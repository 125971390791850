import { Controller } from "@hotwired/stimulus"

/**
 * In order to function properly, make sure the "template" row has as a "association-row" class and is hidden.
 * The delete button will look for this class and will not work without it.
 */
export default class extends Controller {
  static targets = [ "row", "list" ]

  connect() {
    this.newRow = this.rowTarget.cloneNode(true)
    this.count = 0

    this.rowTarget.remove()
  }

  addRow() {
    const newRowCopy = this.newRow.cloneNode(true)

    // Change the IDs of inputs to make sure they will be unique
    newRowCopy.querySelectorAll("input, select, textarea").forEach((input, _) => {
      if (input.id) {
        input.id = `${input.id}_${this.count++}`
      }
    })

    newRowCopy.classList.remove("hidden")
    this.listTarget.appendChild(newRowCopy)
  }

  removeRow(e) {
    const row = e.currentTarget.closest(".association-row")

    if (row == undefined) {
      console.warn("Could not find a 'association-row'. Please add this class to fix the delete function.")
    } else {
      row.remove()
    }
  }
}
