import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loadMore() {
    const list = $(".products-section .list-group tbody")
    const elements = $(list).find("tr.hidden")
    const showButton = $(list).find(".show-products")

    elements.removeClass("hidden")
    showButton.addClass("hidden")
  }

  hide() {
    const list = $(".products-section .list-group tbody")
    const elements = $(list).find("tr.hidden-products")
    const hideButton = $(list).find(".hide-products")
    const showButton = $(list).find(".show-products")

    elements.addClass("hidden")
    hideButton.addClass("hidden")
    showButton.removeClass("hidden")

    $(".products-section").get(0).scrollIntoView({ behavior: "smooth", block: "center" }) // Scroll to top of list
  }
}
