import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    const title = this.element.dataset.title
    $(this.element).DualListBox({ json: false, title: title, moveAllBtn: false })
  }
}
