import { Controller } from "@hotwired/stimulus"

/*
* The modal will automatically close if the form submit it a success.
*/
export default class extends Controller {
  static targets = [ "modal", "content" ]

  connect() {
    // Show the loading content once the modal is closed
    const loadingContent = this.modalTarget.querySelector("turbo-frame")?.innerHTML

    $(this.modalTarget).on("hidden.bs.modal", () => {
      this.contentTarget.innerHTML = loadingContent
    })

    if (this.modalTarget.dataset.open) {
      $(this.modalTarget).modal("show")
    }
  }

  contentTargetConnected() {
    this.reloadJavascriptFeatures()
  }

  open() {
    const element = this.modalTarget

    $(element).modal({
      backdrop: element.dataset.backdrop
    })
  }

  submit(event) {
    if (event.detail.success) {
      $(this.modalTarget).modal("hide")
    }
  }

  /* FUNCTIONS */

  reloadJavascriptFeatures() {
    $("[data-toggle='tooltip']").tooltip({
      container: "body",
      html: true
    })

    $(".datepicker").datetimepicker({
      format: "YYYY-MM-DD",
    })
  }
}
