import { Controller } from "@hotwired/stimulus"
import { initCharts } from "../chart/donut_chart"

export default class extends Controller {
  static targets = [ "chart", "table", "showChart", "showTable" ]

  connect() {
    this.tableTarget.style.display = "none"
    this.showChartTarget.style.display = "none"

    initCharts(this.chartTarget)
  }

  toggle(e) {
    const chart = this.chartTarget
    const table = this.tableTarget
    const showChart = this.showChartTarget
    const showTable = this.showTableTarget

    if (chart.style.display == "none") {
      table.style.display = "none"
      chart.style.display = "block"
      showChart.style.display = "none"
      showTable.style.display = "block"
    } else if (table.style.display == "none") {
      chart.style.display = "none"
      table.style.display = "block"
      showTable.style.display = "none"
      showChart.style.display = "block"
    }
  }
}
